
import Vue from 'vue';
import { _VIEW } from '@shell/config/query-params';
import AsyncButton, { AsyncButtonCallback } from '@shell/components/AsyncButton.vue';
import Banner from '@components/Banner/Banner.vue';

export default Vue.extend({
  components: { AsyncButton, Banner },

  props: {
    /**
     * Current mode of the page
     * passed to asyncButton to determine lables of the button
     */
    mode: {
      type:     String,
      required: true,
    },

    errors: {
      type:    Array,
      default: null,
    },

    disableSave: {
      type:     Boolean,
      default: false,
    }
  },

  computed: {
    isView(): boolean {
      return this.mode === _VIEW;
    },
  },

  methods: {
    save(buttonCb: AsyncButtonCallback) {
      this.$emit('save', buttonCb);
    },

    done() {
      this.$emit('done');
    }
  }
});
